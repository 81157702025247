.block-image {
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
    }
}

.block-image {
    padding: var(--padding-v) var(--padding-h);

    @media (min-width: $smartphone) {
        margin-bottom: var(--padding-m);

        .image {
            width: 80vw;
            margin: auto;
        }
    }
}
