.block-qr {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
    }
}

.block-qr {
    padding: 15vh var(--padding-h) var(--padding-v);

    h1 {
        @include font-xxlarge();
        margin-left: -.04em;
        text-align: center
    }

    .image {
        width: 90%;
        margin: auto;
        margin-top: var(--padding-l);
    }
    
    @media (min-width: $smartphone) {
        .image {
            width: rem(350);
            margin-top: var(--padding-xl);
        }
    }
}
