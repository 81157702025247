.block-startup-list {
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
    }
}

.block-startup-list {
    @include font-sans();
    padding: var(--padding-v) var(--padding-h);

    .startup:last-child {
        border-bottom: 1px solid var(--primary-color);
    }
}
