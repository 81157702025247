.widget-form {
    --padding-h: var(--padding-xl);
    --padding-v: var(--padding-xl);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-s);
    }
}

.widget-form {
    min-height: 100vh;
    padding: var(--padding-v) var(--padding-h);
    width: 100vw;

    @include font-medium();

    .button {
        justify-content: center;
    }
    
    .--big {
        @include font-large();

        @media (max-width: $smartphone) {
            font-size: 16px;
        }
    }

    .--bigger {
        @include font-xlarge();
    }

    .feedback {
        display: none;
        text-align: center;
    }

    &.--good .--good {
        display: block;
    }

    &.--fail .--fail {
        display: block;
    }

    &.--good, &.--fail {
        .form {
            display: none;
        }
    }
}
