.block-text {
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
    }
}

.block-text {
    @include font-sans();
    padding: var(--padding-v) var(--padding-h);

    @media (max-width: $smartphone) {
        .text {
            margin-bottom: var(--padding-m);

            p + p {
                margin-top: var(--padding-s);
            }
        }
    }

    @media (min-width: $smartphone) {
        display: flex;
        align-items: center;
        gap: var(--padding-m);

        .text {
            column-count: 2;
        }

        .image {
            flex: 0 0 55%;
        }
    }
}
