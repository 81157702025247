.block-landing {
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
    }
}

.block-landing {
    padding: calc(var(--padding-v) + var(--header-height)) var(--padding-h) var(--padding-v);

    h1 {
        @include font-xxlarge();
        margin-left: -.04em;
        max-width: rem(550);
    }

    .claim {
        @include font-medium();
        margin-top: var(--padding-s);
    }
    
    .text {
        @include font-base();
    }
    
    @media (max-width: $smartphone) {
        .floating {
            margin-bottom: var(--padding-m);
        }

        .rooms {
            margin-top: var(--padding-l);

            .programme-card:not(:last-child) {
                margin-bottom: var(--padding-l);
            }
        }
    }

    @media (min-width: $smartphone) {
        position: relative;

        .claim {
            margin-top: var(--padding-m);
        }
        
        .text {
            margin-left: 50%;
            max-width: rem(420);

            p + p {
                margin-top: var(--padding-xs);
            }
        }

        .floating {
            position: absolute;
            top: var(--padding-v);
            left: var(--padding-h);
        }

        .rooms {
            margin-top: var(--padding-l);
            display: flex;
            flex-wrap: wrap;
            flex-direction: row-reverse;
            justify-content: space-between;

            .programme-card {
                // &:nth-child(1) {
                //     margin-top: rem(-150);
                //     flex-direction: row-reverse;
                // }

                &:nth-child(2) {
                    margin-right: 35vw;
                }

                // &:nth-child(3) {
                //     margin-top: rem(-200);
                // }
            }
        } 
    }

    @media (min-width: $tabletLandscape) {
        .rooms {
            .programme-card {
                &:nth-child(1) {
                    margin-top: rem(-150);
                    flex-direction: row-reverse;
                }

                &:nth-child(2) {
                    margin-top: rem(250);
                    margin-right: 0;
                }

                &:nth-child(3) {
                    margin-top: rem(-200);
                }
            }
        } 
    }
}
