.widget-assistants {
    --padding-h: var(--padding-xl);
    --padding-v: 25vh;
}

.widget-assistants {
    min-height: 100vh;
    padding: var(--padding-v) var(--padding-h);
    width: 100vw;

    > div > div {
        margin-bottom: var(--padding-l);
    }
}
