@use "sass:math";

#Header {
    --width: 50%;
    --color: var(--primary-color);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);

    --logo-width: #{rem(230)};
    --logo-height: #{rem(42)};

    @media (max-width: $smartphone) {
        --width: 100%;
        --padding-h: var(--padding-xs);
        --logo-width: #{rem(184)};
        --logo-height: #{rem(34)};
    }
}

#Header {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    right: 0;
    top: 0;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
        
    padding: var(--padding-v) var(--padding-h);
    color: var(--color);

    @include font-medium();

    a {
        @include basic-a();
        color: inherit;
    }

    .logo {
        img {
            width: var(--logo-width);
            height: var(--logo-height);
        }
    }

    @media (min-width: $smartphone) {
        padding: var(--padding-v) var(--padding-h) 0 0;
    }
}
