.programme-card {
    display: flex;

    .name {
        @include font-large();
    }

    .button {
        margin-top: var(--padding-s);
    }
    
    @media (max-width: $smartphone) {
        flex-direction: column-reverse;

        .image {
            width: 100%;
            margin-bottom: var(--padding-s);
        }
    }

    @media (min-width: $smartphone) {
        position: relative;
        align-items: center;

        .name {
            width: rem(200);
        }

        .image {
            width: rem(450);
        }
    }
}
