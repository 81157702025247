.billboard-programme {
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
    }
}

.billboard-programme {
    padding: var(--padding-v) var(--padding-h);
    margin-bottom: var(--padding-m);

    h1 {
        @include font-xxlarge();
        margin-left: -.06em;
        margin-bottom: var(--padding-l);
    }
    
    .image {
        margin-top: var(--padding-m);
    }
    
    @media (max-width: $smartphone) {
        padding-top: var(--header-height);
        display: flex;
        flex-direction: column;

        .image { order: 1; }
        .floating { order: 2; }
    }

    @media (min-width: $smartphone) {
        margin-bottom: var(--padding-l);
        
        h1 {
            width: 50%;
        }
        
        .floating {
            position: absolute;
            top: var(--padding-v);
            left: var(--padding-h);
            @include z-index(1);
        }

        .image {
            margin-top: var(--padding-xxl);
            width: 75%;
            margin-left: 25%;
        }
    }
}
