.widget-page-title {
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
    }
}

.widget-page-title {
    padding: var(--padding-v) var(--padding-h);

    h1 {
        @include font-xxlarge();
        margin-left: -.06em;
    }

    @media (max-width: $smartphone) {
        padding-top: var(--header-height);
    }
    
    @media (min-width: $smartphone) {
        margin-bottom: var(--padding-l);
    }
}
