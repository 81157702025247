#CMP {
    --color: var(--black);
    --bg: var(--white);
    --padding: var(--padding-xs);
    --padding-info: var(--padding-xs);
    --border-size: #{rem(1)};
    
    --gap-general: #{rem(80)};
    --gap-btns: var(--padding-xs);
    
    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    @media (max-width: $smartphone) {
        --border-size: 1px;

        --padding: var(--padding-xs);

        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

#CMP {
    @include z-index($z-index-windows);
    @include font-base();

    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100vh;
    opacity: 0;
    
    background-color: var(--bg);
    color: var(--color);
    font-size: var(--font-size);
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
    .content {
        flex: 1 0 40%;
        display: block;
        position: relative;

        padding: var(--padding) calc(var(--padding) + var(--gap-general)) var(--padding) var(--padding);

        @media (max-width: $smartphone) {
            padding: var(--padding);
        }
    }

    > .btns {
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap-btns);
        padding: var(--padding);

        @media (max-width: $smartphone) {
            padding: 0 var(--padding) var(--padding); 
        }
    }

    .btn {
        @include basic-a();
        border-radius: 0;

        border: var(--border-size) solid var(--color);
        padding: var(--padding);
        background-color: var(--bg);
        color: var(--color);
        border-radius: rem(35);

        @include isCursor() {
            transition: background-color .05s ease-out;

            &:hover {
                background-color: var(--color);
                color: var(--bg);
            }
        }

        @media (max-width: $smartphone) {
            padding: var(--padding-xxs);
        }
    }

    a {
        @include basic-a();
        color: var(--color);
        text-decoration: underline;
    }
}

#CMP > .information {
    flex: 1 0 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 var(--padding) var(--padding);
        
    &[aria-expanded='false'] {
        display: none;
    }
}

#CMP > .information > .information__type {
   color: var(--color);
   width: 100%;
   display: flex;
   justify-content: space-between;
   padding: var(--padding-info) 0;
   
   &:not(:last-of-type) {
    border-top: var(--border-size) solid var(--color);
   }
      
   > .title {
        display: block;

        &:empty {
            display: none;
            visibility: hidden;
            pointer-events: none;
        }

        font-size: 1em;
        font-weight: bold;
        white-space: normal;
   }

   > .text {
        flex: 1;
        width: 100;
        white-space: normal;
        max-width: #{rem(1300)};
   }

   > .checks {
        flex: 0 0 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: var(--biga-grey);
   }

    > .checks  > .check {
        --width: #{rem(45)};
        --height: #{rem(26)};
        --radius: #{rem(16)};
        --thumb-width: #{rem(20)};
        --thumb-offset: calc((var(--height) - var(--thumb-width)) / 2);
        --position: var(--thumb-offset);
        --opacity: 1;

        &.--disabled,
        &.--active {
            --position: calc(var(--width) - var(--thumb-width) - var(--thumb-offset));
        }

        &.--disabled {
            --opacity: .4;
            pointer-events: none;
        }

        &.--active {
            --color: var(--blue);
        }

        position: relative;
        width: var(--width);
        height: var(--height);
        border-radius: var(--radius);
        
        &::before {
            content: '';
            position: absolute;
            top:0;
            left: 0;
            right: 0;
            bottom: 0;
            border: var(--border-size) solid var(--color);
            border-radius: var(--radius); 
            background-color: var(--bg);
        }

        &::after {
            content: '';
            position: absolute;
            top: var(--thumb-offset);
            left: 0;
            width: var(--thumb-width);
            height: var(--thumb-width);
            border-radius: var(--height); 
            background-color: var(--color);
            transform: translateX(var(--position));
            transition: transform .1s ease-out;
        }
    }
}

// #CMP > .information > .information__controls {
//     width: 100%;
//     display: flex;
//     justify-content: flex-end;
//     padding: 0 0 var(--padding);
// }

// @media (max-width: $smartphone) {
//     #CMP {
//         .content {
//             padding: var(--padding);
//             flex: 1 0 100%;
//         }

//         > .btns {
//             display: grid;
//             grid-template-columns: 1fr 1fr;
//             padding: 0 var(--padding) var(--padding);
//             flex: 1 0 100%;

//             > .btn:first-child {
//                 grid-column: 1 / 3;
//             }
//         }
//     }

//     #CMP > .information > .information__type {
//         > .checks {
//             align-items: flex-start;
//             justify-content: flex-start;
//             width: #{rem(38)};
//         }

//         > .text {
//             padding-right: var(--padding-info);
//         }

//         > .checks  > .check {
//             --width: #{rem(38)};
//             --height: #{rem(22)};
//             --radius: #{rem(11)};
//             --thumb-width: #{rem(16)};
//         }
//     }
// }