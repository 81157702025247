#Footer {
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
    }
}

#Footer {
    @include font-base();
    align-items: center;
    display: flex;
    padding: var(--padding-v) var(--padding-h);
    
    a,
    button {
        @include basic-a();
        color: var(--white);
        background-color: transparent;
        padding: 0;
    }
    
    @media (max-width: $smartphone) {
        font-size: 14px;
        flex-wrap: wrap;

        > * {
            text-align: left;
        }

        > *:nth-child(n + 3) {
            margin-top: var(--padding-s);
        }
    }
        
    @media (min-width: $smartphone) {
        justify-content: flex-start;
        gap: var(--padding-l);
        margin-top: var(--padding-s);
    }
}
