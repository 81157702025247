@use "sass:math";

:root {
    --font-sans: 'TitilliumWeb', sans-serif;

    --font-size-xxlarge: #{math.div(132px, 16px) * 1rem};
    --font-size-xlarge: #{math.div(56px, 16px) * 1rem};
    --font-size-large: #{math.div(38px, 16px) * 1rem};
    --font-size-medium: #{math.div(24px, 16px) * 1rem};
    --font-size-base: #{math.div(15px, 16px) * 1rem};

    --zoom: 1;
    --line-height-sans: 1;
    --line-height-titles: .79;
    --letter-spacing: 0;

    --min-font-size: 10px;
    --max-font-size: 48px;
    --font-size: clamp(var(--min-font-size), 1.1111vw * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        --font-size: 16px;
        --line-height-titles: 1;
        --font-size-xxlarge: #{math.div(62px, 16px) * 1rem};
        --font-size-xlarge: #{math.div(28px, 16px) * 1rem};
        --font-size-large: #{math.div(28px, 16px) * 1rem};
        --font-size-medium: #{math.div(18px, 16px) * 1rem};
    }
}

@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-base() {
    @include font-sans();
    font-size: var(--font-size-base);
    font-weight: 500;
}

@mixin font-medium() {
    @include font-sans();
    font-size: var(--font-size-medium);
    font-weight: 500;
}

@mixin font-large() {
    @include font-sans();
    font-size: var(--font-size-large);
    font-weight: 600;
}

@mixin font-xlarge() {
    @include font-sans();
    line-height: var(--line-height-titles);
    font-size: var(--font-size-xlarge);
    font-weight: 600;
}

@mixin font-xxlarge() {
    @include font-sans();
    line-height: var(--line-height-titles);
    font-size: var(--font-size-xxlarge);
    font-weight: 600;
}
