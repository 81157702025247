@mixin button {
    --color: var(--primary-bg);
    --bg: var(--primary-color);
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-m);

    @include font-medium();

    align-items: center;
    appearance: none;
    background: var(--bg);
    border: 1px solid var(--bg);
    cursor: pointer;
    color: var(--color);
    display: inline-flex;
    justify-content: flex-start;
    padding: var(--padding-v) var(--padding-h);
    position: relative;
    text-align: center;
    vertical-align: top;
    text-decoration: none;
    border-radius: var(--padding-l);

    * { pointer-events: none; }
    span { display: block; }

    @include isCursor() {
        &:hover {
            color: var(--bg);
            background-color: var(--color);
        }
    }
}

.button {
    @include button;
}