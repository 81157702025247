.programme {
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xs);
    }
}

.programme {
    display: flex;
    align-items: center;
    padding: var(--padding-v) var(--padding-h);

    .name {
        @include font-large();
    }

    .abstract {
        @include font-base();
        margin-top: var(--padding-s);
    }

    .button {
        margin-top: var(--padding-xs);
    }
        
    @media (max-width: $smartphone) {
        flex-direction: column-reverse;

        .image {
            width: 100%;
            margin-bottom: var(--padding-s);
        }
    }

    @media (min-width: $smartphone) {
        position: relative;
        gap: var(--padding-s);

        &:nth-child(even) {
            flex-direction: row-reverse;
        }

        .name {
            width: rem(200);
        }

        .image {   
            flex: 0 0 55%;
        }
    }
}
