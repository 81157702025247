#Preloader {
    --color: var(--white);
    --bg: var(--blue);
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    @include z-index($z-index-loader);
    background-color: var(--bg);
    
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
}
