.startup {
    --color: var(--primary-color);
    --bg: transparent;
}

.startup {
    border-top: 1px solid var(--color);
    color: var(--color);
    background-color: var(--bg);
    padding: var(--padding-xs) 0 var(--padding-xl);
    position: relative;

    @include font-base();

    .name {
        @include font-medium();
    }
    
    .country {
        margin: var(--padding-xxxs) 0 var(--padding-xxs);
    }
    
    .logo {
        width: rem(100);
        height: auto;
        padding: rem(2);
        background-color: white;
        border-radius: rem(4);
        overflow: hidden;

        img {
            border-radius: rem(2);
        }
    }
    
    .text {
        max-width: rem(450);    
    }

    .chapuza {
        img {
            opacity: 0;
        }
    }

    &.--active {
        .chapuza {
            img {
                opacity: 1;
            }
        }
    }

    @media (max-width: $smartphone) {
        .text {
            margin-top: var(--padding-s);
        }

        .chapuza {
            img {
                width: rem(50);
                position: absolute;
                top: var(--padding-xs);
                right: 0;
            }
        }
    }

    @media (min-width: $smartphone) {
        display: flex;
        gap: var(--padding-s);

        .logo {
            width: rem(200);
        }

        div:first-child {
            flex: 0 0 rem(250);
        }

        .chapuza {
            flex-grow: 1;
            padding-right: rem(50);
            display: flex;
            align-items: center;
            justify-content: flex-end;

            img {
                width: rem(120);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}